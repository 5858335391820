try {
    const pathname = window.location.pathname.replace('/', '');
    const hash = window.location.hash.split('?')[0].replace('/', '');
    const hasHash = window.location.href.indexOf('#') >= 0;
    const rootPath = pathname === '' || pathname === '/';
    const rootHash = hash === '#' || hash === '#/';

    const args = window.location.pathname.slice(1).split('/');

    if (args.length !== 2 || args[1].split('-').length !== 5) {
        // HIP-10733 if NOT a partner page.. /name/UUID-PATTERN
        document.body.style.display = '';
    }

    document.body.classList.remove('preloading'); // used to remove preloaded version of landing page

    const userAgent = window.navigator.userAgent;
    const isIE = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
    if (isIE) {
        document.body.classList.add('msie');
    }
    const isFirefox = userAgent.indexOf('Firefox') > -1;
    if (isFirefox) {
        document.body.classList.add('firefox');
    }
    const isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1;
    if (isSafari) {
        document.body.classList.add('safari');
    }

    if (rootPath || (hasHash && rootHash)) {
        document.body.classList.add('loaded'); // need loaded to hide the 'loading' animation
    }
} catch (e) {
    const logger = console;
    if (logger && logger.log) {
        logger.log('error happened', e);
    }
}
